/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { Wrapper, StyledLink, StyledAnchor } from '../common';
import SiteContainer from '../../SiteContainer';

const FooterCTAWrapper = styled(Wrapper)`
	padding-top: 80px;
	padding-bottom: 80px;
	background-image: linear-gradient(120deg, #fefd82, #aeffa8 29.94%, #8fffce 66.98%, #99f7ff);
	color: #202124;
	text-align: center;

	.hostjane-footer-cta__title {
		padding: 32px 20px 0;
		font-size: 36px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 44px;
		margin: 0;
		font-style: italic;
	}
	.hostjane-footer-cta__section {
		margin: 40px 0 0 0;
		padding-bottom: 16px; /** Need extra */
	}
	.hostjane-footer-cta__sectiontitle {
		font-size: 23px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 30px;
		margin: 0;
	}
	.hostjane-footer-cta__sectionbuttons {
		margin: 24px 0 0 0;
	}
	.hostjane-button {
		width: 80%;
		margin: 0 auto 16px;
		display: flex;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.hostjane-footer-cta__lists {
		margin: 56px 0 0 0;
	}
	.hostjane-footer-cta__list {
		color: #000;
		font-size: 18px;
		font-weight: 400;
		line-height: 28px;
		a {
			color: #1a73e8;
			text-decoration: none;
			&:hover {
				color: #1a73e8;
				text-decoration: none;
			}
		}
	}
	.hostjane-footer-cta__left__footnote {
		margin: 64px 0 0;
		color: #5f6368;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.35px;
		line-height: 18px;
		padding: 0 20px;
		p {
			margin: 0;
		}
	}

	@media screen and (min-width: 768px) {
		padding-top: 120px;
		padding-bottom: 120px;
		text-align: left;
		.hostjane-footer-cta__title {
			padding: 40px 0 15px 28px;
			font-size: 48px;
			letter-spacing: -0.5px;
			line-height: 58px;
		}
		.hostjane-footer-cta__section {
			padding-left: 28px;
			padding-bottom: 0;
		}
		.hostjane-footer-cta__sectionbuttons {
			display: flex;
			flex-flow: row wrap;
		}
		.hostjane-button {
			width: auto;
			margin: 0 16px 16px 0;
			display: flex;
			max-width: 232px;
			flex: 1;
		}
		.hostjane-footer-cta__lists {
			padding-left: 28px;
			margin-top: 40px;
		}
		.hostjane-footer-cta__left__footnote {
			margin-top: 96px;
			padding-left: 28px;
		}
	}

	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.desktop}px) {
		.hostjane-footer-cta__columns {
			display: flex;
			flex-flow: row nowrap;
			padding-top: 40px;
		}
		.hostjane-footer-cta__column--left {
			flex: 0 1 65%;
			display: flex;
			flex-flow: row wrap;

			.hostjane-footer-cta__title {
				padding-top: 0;
				flex: 0 0 100%;
			}
			.hostjane-footer-cta__section {
				flex: 1 1 50%;
			}
		}
		.hostjane-footer-cta__column--right {
			flex: 1 1 auto;
			padding-left: 40px;
			margin-left: 40px;
			border-left: 1px solid #dadce0;
			align-self: center;

			.hostjane-footer-cta__lists {
				margin-top: 0;
			}
		}
		.hostjane-button {
			max-width: 180px;
			flex: 0 1 auto;
		}
	}
`;

export default function ({ data, id }) {
	return (
		<FooterCTAWrapper id={id} className="hostjane-footer-cta">
			<SiteContainer>
				<div className="hostjane-footer-cta__columns">
					<div className="hostjane-footer-cta__column hostjane-footer-cta__column--left">
						<h2 className="hostjane-footer-cta__title">
							{data.title}
						</h2>
						{data.sections.map((section, index) => (
							<div
								className="hostjane-footer-cta__section"
								key={index}
							>
								<h3 className="hostjane-footer-cta__sectiontitle">
									{section.title}
								</h3>
								<div className="hostjane-footer-cta__sectionbuttons">
									{section.buttons.map((button, bi) =>
										button.internal ? (
											<StyledLink
												key={bi}
												to={button.link}
												className={classNames(
													'hostjane-button',
													`hostjane-button--${
														button.type ||
														'secondary'
													}`
												)}
											>
												{button.title}
											</StyledLink>
										) : (
											<StyledAnchor
												key={bi}
												href={button.link}
												className={classNames(
													'hostjane-button',
													`hostjane-button--${
														button.type ||
														'secondary'
													}`
												)}
											>
												{button.title}
											</StyledAnchor>
										)
									)}
								</div>
							</div>
						))}
					</div>

					<div className="hostjane-footer-cta__column hostjane-footer-cta__column--right">
						<div className="hostjane-footer-cta__lists">
							{data.list.map((l, i) => (
								<p
									className="hostjane-footer-cta__list"
									key={i}
								>
									{l.label}
									<br />
									{l.internal ? (
										<Link
											to={l.link}
											className="hostjane-footer-cta__listanchor"
										>
											{l.title}
										</Link>
									) : (
										<a
											href={l.link}
											className="hostjane-footer-cta__listanchor"
										>
											{l.title}
										</a>
									)}
								</p>
							))}
						</div>
					</div>
				</div>
				<div className="hostjane-footer-cta__left__footnote">
					<p>{data.footer}</p>
				</div>
			</SiteContainer>
		</FooterCTAWrapper>
	);
}
