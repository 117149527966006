/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import classNames from 'classnames';

import footerData from '../../../site-data/common/footer';
import { HostJaneLogoLink } from '../MainNavigation/common';
import GenericIcon from '../GenericIcon';

const FooterContainer = styled.footer`
	margin: 5px auto 0;
	max-width: ${props => props.theme.siteApp.maxWidth}px;
	padding: 0;

	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.desktop}px) {
		margin-top: 40px;
		padding: 0 40px;
	}

	.hostjane-main-footer__icon {
		position: relative;
		display: flex;
		margin-bottom: 10px;

		&::before {
			content: '';
			display: block;
			height: 4px;
			margin-top: 0px;
			border-bottom: 1px solid rgb(226, 226, 226);
			border-top: 2px solid rgb(226, 226, 226);
			width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.hostjane-main-footer__iconlink {
		position: relative;
		padding: 11px 20px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		background-color: #fff;
		width: 250px;
		svg {
			width: 225px;
		}
	}

	/** Social Part */
	.hostjane-main-footer__socials {
		margin: 0 0 20px;
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			margin-bottom: 30px;
		}
	}
	.hostjane-main-footer__sociallist {
		list-style: none;
		list-style-image: none;
		max-width: 395px;
		margin: 0 auto;
		margin-bottom: 15px;
		padding: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: 576px) {
			justify-content: space-evenly;
		}
	}
	.hostjane-main-footer__sociallistli {
		width: 32px;
		height: 32px;
		border-radius: 100%;
		transition: background 0.2s;
		padding: 0;
		&:last-child {
			margin-right: 0;
		}
		&:hover {
			background-color: #efeff0;
		}

		a {
			display: flex;
			height: 32px;
			width: 32px;
			align-items: center;
			justify-content: center;
			font-size: 32px;
			color: #777;
			text-decoration: none;
			&:hover {
				color: #777;
				text-decoration: none;
			}
		}

		&.hostjane-main-footer__sociallistli--linkedIn {
			a {
				svg {
					position: relative;
					top: -2px;
				}
			}
		}

		@media screen and (max-width: 576px) {
			&:last-of-type {
				display: none;
			}
			&.hostjane-main-footer__sociallistli--pinterest {
				display: none;
			}
			&.hostjane-main-footer__sociallistli--discord {
				margin-right: 0;
			}
		}
	}
	.hostjane-main-footer__socialdesc {
		padding: 0 20px;
		font-size: 18px;
		color: #000;
		max-width: 600px;
		margin: 0 auto;
		text-align: center;
		font-weight: 500;
		line-height: 1.5;
	}

	/** Columns */
	.hostjane-main-footer__columns {
		padding: 0 20px;
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			padding: 30px 0 9px 0;
			display: flex;
			flex-flow: row nowrap;
			border-top: 1px solid rgb(226, 226, 226);
		}
	}
	.hostjane-main-footer__column {
		&.hostjane-main-footer__column--connect {
			display: none;
		}
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			flex: 1 1 0%;
			&.hostjane-main-footer__column--connect {
				display: block;
				padding-left: 15px;
				border-left: 1px solid rgb(226, 226, 226);
			}
		}
	}

	.hostjane-main-footer__columntitle {
		text-transform: uppercase;
		cursor: pointer;
		margin: 0;
		border-top: 1px solid #ebebeb;
		color: #333;
		font-size: 13px;
		font-weight: 700;
		height: 44px;
		letter-spacing: 0.56px;
		line-height: 44px;
		text-transform: uppercase;

		.hostjane-main-footer__columntitleregular {
			display: none;
		}
		.hostjane-main-footer__columntitlemobile {
			display: block;
		}

		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			height: 25px;
			line-height: 25px;
			border-top: 0 none;
			cursor: default;

			.hostjane-main-footer__columntitleregular {
				display: block;
			}
			.hostjane-main-footer__columntitlemobile {
				display: none;
			}
		}
	}
	.hostjane-main-footer__columnlist {
		display: none;
		&.hostjane-main-footer__columnlist--open {
			display: block;
		}

		column-width: auto;
		column-count: 2;
		list-style: none;
		margin: 0px;
		padding: 0px 0px 15px;

		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			display: block;
			column-count: 1;
			column-width: initial;
		}

		&.hostjane-main-footer__columnlist--withicons {
			padding: 0;
			.hostjane-main-footer__columnlistitem {
				margin-bottom: 5px;
				white-space: nowrap;
				height: 27px;
				line-height: 1.4;
			}
			.hostjane-main-footer__columnlistanchor {
				color: rgb(0, 0, 0);
				display: inline-block;
				text-transform: capitalize;
				width: 150px;
				font-size: 16px;
				font-weight: 700;
				height: 25px;
				line-height: 15px;
				padding-bottom: 0px;
				text-decoration: none;

				.hostjane-main-footer__columnlistanchoricon {
					height: 13px;
					width: 13px;
					margin-right: 7px;
					font-size: 13px;
					color: #000;
				}

				&:hover {
					color: #0056b3;
					.hostjane-main-footer__columnlistanchoricon {
						color: #000;
					}
				}
			}
		}
		&.hostjane-main-footer__columnlist--small {
			margin: 0;
			padding: 0;
			.hostjane-main-footer__columnlistitem {
				height: 23px;
				line-height: 1.4;
			}
			.hostjane-main-footer__columnlistanchor {
				color: rgb(0, 0, 0);
				text-transform: capitalize;
				width: 150px;
				font-size: 16px;
				height: 23px;
				line-height: 21px;
				text-decoration: none;
				&:hover {
					color: #0056b3;
				}
			}

			&.hostjane-main-footer__columnlist--smalltwo {
				list-style: none;
				margin: 2px 0px 0px;
				padding: 10px 0px 0px 0px !important;
				border-top: 1px solid rgb(226, 226, 226);
			}
		}
	}
	.hostjane-main-footer__columnlistitem {
		height: 34px;
		line-height: 34px;
		list-style-type: none;
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			height: 23px;
			line-height: 23px;
		}
	}
	.hostjane-main-footer__columnlistanchor {
		color: rgb(51, 51, 51);
		display: block;
		font-size: 16px;
		height: 34px;
		line-height: 34px;
		text-transform: capitalize;
		text-decoration: none;
		transition: all 300ms ease-in-out;
		font-weight: 400;
		&:hover {
			text-decoration: none;
			color: #0056b3;
		}
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			height: 23px;
			line-height: 23px;
			font-size: 16px;
		}
	}

	/** Bottom part */
	.hostjane-main-footer__bottom {
		padding-top: 9px;
		border-top: 1px solid rgb(235, 235, 235);
		font-size: 14px;
		text-align: center;
	}
	.hostjane-main-footer__bottomul {
		list-style: none;

		padding: 0;
		text-align: center;
		li {
			display: inline-block;
		}
		a {
			color: #333;
			white-space: nowrap;
			padding: 10px 0px;
			text-decoration: none;
			&:hover {
				color: #333;
				text-decoration: none;
			}
		}

		&.hostjane-main-footer__bottomul--first {
			margin: 0 0 15px;
			margin-left: 15px;
			font-size: 16px;
			color: #333;
		}
		&.hostjane-main-footer__bottomul--second {
			list-style: none;
			line-height: 8px;
			padding-left: 0 !important;
			margin: 0 0 35px;
			padding: 0;
			li {
				border-right: 1px solid #ebebeb;
				display: inline-block;
				line-height: 20px;
				padding: 0 8px;
				font-size: 16px;
				color: #333;
			}
		}

		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			display: inline-block;
		}
	}
	.hostjane-main-footer__bottomjip {
		margin: 0 0 15px 0;
		text-align: center;
		a {
			color: #dee2e6;
			font-size: 16px;
			letter-spacing: 8px;
			padding-bottom: 1px;
			text-decoration: none;
			text-transform: uppercase;
			font-weight: 800;
			&:hover {
				color: #000000;
				text-decoration: none;
			}
		}
	}
`;

function ListItem({ item }) {
	return (
		<li className="hostjane-main-footer__columnlistitem">
			{item.internal ? (
				<Link
					className="hostjane-main-footer__columnlistanchor"
					to={item.link}
				>
					{item.icon ? (
						<GenericIcon className="hostjane-main-footer__columnlistanchoricon">
							<item.icon />
						</GenericIcon>
					) : null}
					{item.title}
				</Link>
			) : (
				<a
					className="hostjane-main-footer__columnlistanchor"
					href={item.link}
				>
					{item.icon ? (
						<GenericIcon className="hostjane-main-footer__columnlistanchoricon">
							<item.icon />
						</GenericIcon>
					) : null}
					{item.title}
				</a>
			)}
		</li>
	);
}

function ListColumn({ column, type }) {
	const [open, setOpen] = useState(false);
	return (
		<div
			className={classNames(
				'hostjane-main-footer__column',
				`hostjane-main-footer__column--${type}`
			)}
		>
			<h3
				className="hostjane-main-footer__columntitle"
				tabIndex={0}
				onClick={() => {
					setOpen(o => !o);
				}}
				onKeyDown={e => {
					if (e.keyCode === 13 || e.keyCode === 32) {
						setOpen(o => !o);
					}
				}}
			>
				<span className="hostjane-main-footer__columntitleregular">
					{column.title}
				</span>
				<span className="hostjane-main-footer__columntitlemobile">
					{column.mobileTitle ? column.mobileTitle : column.title}
				</span>
			</h3>
			<ul
				className={classNames('hostjane-main-footer__columnlist', {
					'hostjane-main-footer__columnlist--open': open,
				})}
			>
				{column.items.map((item, itemIndex) => (
					<ListItem key={itemIndex} item={item} />
				))}
			</ul>
		</div>
	);
}

export default function MainFooter() {
	const { socialPart, columns, connect, footLinks } = footerData;
	return (
		<FooterContainer className="hostjane-main-footer" id="footer-main">
			<div className="hostjane-main-footer__icon">
				<HostJaneLogoLink
					className="hostjane-main-footer__iconlink"
					to="https://www.hostjane.com"
					width={150}
				/>
			</div>
			<div className="hostjane-main-footer__socials">
				<ul className="hostjane-main-footer__sociallist">
					{Object.keys(socialPart.socials).map((social, index) => {
						const Icon = socialPart.socials[social].icon;
						return (
							<li
								className={classNames(
									'hostjane-main-footer__sociallistli',
									`hostjane-main-footer__sociallistli--${social}`
								)}
								key={social}
							>
								<a href={socialPart.socials[social].link}>
									<GenericIcon
										title={`HostJane on ${socialPart.socials[social].title}`}
									>
										<Icon />
									</GenericIcon>
								</a>
							</li>
						);
					})}
				</ul>
				<div className="hostjane-main-footer__socialdesc">
					{socialPart.description}
				</div>
			</div>
			<div className="hostjane-main-footer__columns">
				{columns.map((column, index) => (
					<ListColumn key={`column-${index}`} column={column} />
				))}
				<div
					className={classNames(
						'hostjane-main-footer__column',
						'hostjane-main-footer__column--connect',
						`hostjane-main-footer__column`
					)}
				>
					<h3 className="hostjane-main-footer__columntitle">
						<span className="hostjane-main-footer__columntitleregular">
							{connect.title}
						</span>
						<span className="hostjane-main-footer__columntitlemobile">
							{connect.mobileTitle
								? connect.mobileTitle
								: connect.title}
						</span>
					</h3>
					<ul
						className={classNames(
							'hostjane-main-footer__columnlist',
							'hostjane-main-footer__columnlist--withicons'
						)}
					>
						{connect.withIcons.map((item, index) => (
							<ListItem key={index} item={item} />
						))}
					</ul>
					<ul
						className={classNames(
							'hostjane-main-footer__columnlist',
							'hostjane-main-footer__columnlist--small',
							'hostjane-main-footer__columnlist--smallone'
						)}
					>
						{connect.smallOne.map((item, index) => (
							<ListItem key={index} item={item} />
						))}
					</ul>
					<ul
						className={classNames(
							'hostjane-main-footer__columnlist',
							'hostjane-main-footer__columnlist--small',
							'hostjane-main-footer__columnlist--smalltwo'
						)}
					>
						{connect.smallTwo.map((item, index) => (
							<ListItem key={index} item={item} />
						))}
					</ul>
				</div>
			</div>
			<div className="hostjane-main-footer__bottom">
				<ul className="hostjane-main-footer__bottomul hostjane-main-footer__bottomul--second">
					{footLinks.others.map((ot, index) => (
						<li key={index}>
							{ot.internal ? (
								<Link to={ot.link}>{ot.title}</Link>
							) : (
								<a href={ot.link}>{ot.title}</a>
							)}
						</li>
					))}
				</ul>
				<ul className="hostjane-main-footer__bottomul hostjane-main-footer__bottomul--first">
					<li>
						{footLinks.copyright.internal ? (
							<Link to={footLinks.copyright.link}>
								{footLinks.copyright.title}
							</Link>
						) : (
							<a href={footLinks.copyright.link}>
								{footLinks.copyright.title}
							</a>
						)}
					</li>
				</ul>
				<div className="hostjane-main-footer__bottomjip">
					{footLinks.jip.internal ? (
						<Link to={footLinks.jip.link}>
							{footLinks.jip.title}
						</Link>
					) : (
						<a href={footLinks.jip.link}>{footLinks.jip.title}</a>
					)}
				</div>
			</div>
		</FooterContainer>
	);
}
