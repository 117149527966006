import React from 'react';
import styled from 'styled-components';

const tabletNavigationData = [
	{
		link: 'Web / Mobile / Tech',
		href:
			'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers',
		dropdownItems: [
			{
				link: 'Explore All',
				href:
					'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers',
			},
			{
				link: 'Web Design / Apps',
				href:
					'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-app-design',
			},
			{
				link: 'Web Development',
				href:
					'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development',
			},
			{
				link: 'WordPress',
				href:
					'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress',
			},
			{
				link: 'Programming / Software',
				href:
					'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software',
			},
			{
				link: 'Math / Science Help',
				href:
					'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms',
			},
		],
	},
	{
		href:
			'https://www.hostjane.com/marketplace/categories/design-art-video-voice',
		link: 'Design / Art / Video / Audio',
		dropdownItems: [
			{
				link: 'Explore All',
				href:
					'https://www.hostjane.com/marketplace/categories/design-art-video-voice',
			},
			{
				link: 'Graphic Design / Logos',
				href:
					'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos',
			},
			{
				link: 'Freelance Artists',
				href:
					'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art',
			},
			{
				link: 'Video / Animation',
				href:
					'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation',
			},
			{
				link: 'Illustration',
				href:
					'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration',
			},
		],
	},
	{
		href: 'https://www.hostjane.com/marketplace/categories/online-tutors',
		link: 'Bookings',
		dropdownItems: [
			{
				link: 'Explore All',
				href:
					'https://www.hostjane.com/marketplace/categories/online-tutors',
			},
			{
				link: 'Language Tutors',
				href:
					'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors',
			},
			{
				link: 'Live Chats / Therapists',
				href:
					'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats',
			},
			{
				link: 'Online Experiences',
				href:
					'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons',
			},
			{
				link: 'Math Lessons',
				href:
					'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons',
			},
			{
				link: 'Music Lessons',
				href:
					'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons',
			},
		],
	},
	{
		href:
			'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers',
		link: 'Writing / Translation',
		dropdownItems: [
			{
				link: 'Explore All',
				href:
					'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers',
			},
			{
				link: 'Translation',
				href:
					'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation',
			},
			{
				link: 'Creative Writing',
				href:
					'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing',
			},
			{
				link: 'Book Writing',
				href:
					'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing',
			},
			{
				link: 'General Writing',
				href:
					'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing',
			},
			{
				link: 'Copywriting',
				href:
					'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting',
			},
		],
	},
	{
		href:
			'https://www.hostjane.com/marketplace/categories/business-admin-support',
		link: 'Business',
		dropdownItems: [
			{
				link: 'Explore All',
				href:
					'https://www.hostjane.com/marketplace/categories/business-admin-support',
			},
			{
				link: 'Digital Marketing',
				href:
					'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing',
			},
			{
				link: 'Accounting / Tax',
				href:
					'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax',
			},
			{
				link: 'Business Consulting',
				href:
					'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting',
			},
			{
				link: 'Legal Assistance',
				href:
					'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance',
			},
			{
				link: 'Virtual Assistants',
				href:
					'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants',
			},
		],
	},
	{
		href: 'https://www.hostjane.com/marketplace/categories',
		link: 'See All',
	},
];

const TabletNavigationWrapper = styled.nav`
	&.hostjane-main__tabletNavigation {
		width: 100%;
		transition: transform 0.35s ease-out;
		transform: translateY(0);
		background-color: #222222;
		border-top: 1px solid #ffffff;
		box-shadow: 0px 6px 7px rgba(57, 73, 76, 0.35);
		height: 48px;
		display: none;
		position: relative;
		z-index: 2;
		margin-top: 8px;
		@media screen and (min-width: 768px) and (max-width: 1330px) {
			display: block;
		}
		> div {
			max-width: 1408px;
			padding: 0px 30px;
			margin-left: auto;
			margin-right: auto;
			height: 100%;
		}
		.hostjane-main__tabletNavigation__list {
			height: 100%;
			font-size: 12px;
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: space-between;
			.hostjane-main__tabletNavigation__list__item {
				display: flex;
				align-items: center;
				height: 100%;
				position: relative;
				justify-content: center;
				&:first-of-type {
					margin-left: 0px;
				}
				.hostjane-main__tabletNavigation__list__item__main-link {
					text-decoration: none;
					// border-radius: 4px 4px 0 0;
					color: white;
					font-weight: 400;
					// display: block;
					white-space: nowrap;
					text-decoration: none;
					font-size: 16px;
					line-height: 12px;
					padding: 0 10px;
					height: 100%;
					display: flex;
					align-items: center;
					&:focus,
					&:active {
						outline: none;
					}
					@media (max-width: 850px) {
						font-size: 12px;
					}
				}
				.hostjane-main-dropdown {
					display: none;
					left: 0;
					position: absolute;
					top: 47px;
					left: 0;
					right: -29px;
					min-width: 143px;
					ul {
						box-shadow: 0 3px 10px hsl(0, 0%, 30%);
						list-style: none;
						padding: 0;
						li {
							a {
								text-decoration: none;
								background-color: hsl(0, 0%, 100%);
								color: #000;
								display: block;
								padding: 8px;
								font-size: 18px;
								text-decoration: none;
								&:hover {
									background-color: hsl(0, 0%, 90%);
									text-decoration: none;
								}
							}
							&:first-of-type {
								border-bottom: 1px solid hsl(0, 0%, 90%);
							}
						}
					}
				}

				&:hover {
					.hostjane-main__tabletNavigation__list__item__main-link {
						background-color: #007bff;
						color: hsl(0, 0%, 100%);
						color: #ffffff;
					}
					.hostjane-main-dropdown {
						display: block;
					}
				}
			}
		}
	}
`;
const TabletNavigation = () => {
	return (
		<TabletNavigationWrapper className="hostjane-main__tabletNavigation">
			<div>
				<ul className="hostjane-main__tabletNavigation__list">
					{tabletNavigationData.map(
						({ link, href, dropdownItems }, key) => {
							return (
								<li
									key={key}
									className="hostjane-main__tabletNavigation__list__item"
								>
									<a
										href={href}
										className="hostjane-main__tabletNavigation__list__item__main-link"
									>
										{link}
									</a>
									{dropdownItems?.length > 0 && (
										<div className="hostjane-main-dropdown">
											<ul>
												{dropdownItems.map(
													({ link, href }, key) => {
														return (
															<li key={key}>
																<a href={href}>
																	{link}
																</a>
															</li>
														);
													}
												)}
											</ul>
										</div>
									)}
								</li>
							);
						}
					)}
				</ul>
			</div>
		</TabletNavigationWrapper>
	);
};

export default TabletNavigation;
