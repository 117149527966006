/* eslint-disable react/no-array-index-key */
import React, { createContext, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import useMeasure from 'react-use-measure';

import {
	NavContainer,
	HostJaneLogoLink,
	NavDirectLink,
	NavDirectLinkGroup,
	NavDirectLinkWithChildren,
	NavSearch,
	NavDivider,
} from './common';
import desktopNavData from '../../../site-data/common/nav/desktop';
import { ReactComponent as SkillsIcon } from '../../svgs/icons/skills.svg';
import { ReactComponent as ChevronRightIcon } from '../../svgs/icons/chevron-right.svg';
import GenericIcon from '../GenericIcon';
import { ReactComponent as SellTeachIcon } from '../../svgs/icons/sell-teach.svg';
import Hamburger_Main from './Hamburger_Main';

function HoverArrowIcon() {
	return (
		<svg
			className="HoverArrow"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			ariaHidden="true"
		>
			<g fillRule="evenodd" stroke="currentColor">
				<path className="HoverArrow__linePath" d="M0 5h7" />
				<path className="HoverArrow__tipPath" d="M1 1l4 4-4 4" />
			</g>
		</svg>
	);
}

// Width of popup columns
const popupColumnWidth = 300;

// Some context and provider to get main navigation width.
const mainNavBoundsContext = createContext();

function useMainNavBounds() {
	return useContext(mainNavBoundsContext);
}

const DesktopNavHeader = styled.header`
	padding: ${props => props.theme.gutter.small}px
		${props => props.theme.gutter.large}px;
	max-width: ${props => props.theme.siteApp.maxWidth}px;
	margin: 0 auto;
	${props => props.landingPage && 'padding-bottom: 0;'}
	.hostjane-desktop-nav__logo {
		margin-right: 30px;
		transform: translateY(5px);
	}

	.hostjane-nav-search {
		width: 100%;
		margin-left: 25px;
		@media screen and (min-width: 1400px) {
			width: 100%;
		}
	}

	.hostjane-desktop-nav__links {
		margin-left: auto;
	}

	.hostjane-desktop-nav__skills {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		height: 40px;
		width: 40px;
		position: relative;
		font-size: 16px;

		color: ${props => props.theme.siteApp.navAnchorColor};
		text-decoration: none;
		background-color: transparent;
		transition: background-color ${props => props.theme.siteApp.transition},
			color ${props => props.theme.siteApp.transition};
		will-change: background-color, color;

		&::before {
			position: absolute;
			top: -10px;
			left: -10px;
			height: 60px;
			width: 60px;
			content: '';
			background-color: ${props => props.theme.siteApp.navColor};
			opacity: 0;
			z-index: -1;
			border-radius: 100%;
			transition: transform ${props => props.theme.siteApp.transition},
				opacity ${props => props.theme.siteApp.transition};
			will-change: transform, opacity;
			transform: scale(0.5, 0.5);
		}

		&:hover {
			color: ${props => props.theme.siteApp.navAnchorHoverColor};
			&::before {
				transform: scale(1, 1);
				opacity: 0.075;
			}
		}
	}
	.hostjane-desktop-nav__skillsicon {
		font-size: 24px;
		margin-bottom: 2px;
	}

	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.desktop}px) {
		margin-bottom: 20px;
		${props => props.landingPage && 'margin-bottom: 0;'}
	}

	.hostjane-desktop-nav__sellteach {
		.hostjane-nav__linkicon,
		.hostjane-nav__linkicon svg {
			height: 1em;
			width: 1em;
			color: #88add2;
		}
		&:hover,
		&.active {
			.hostjane-nav__linkicon svg {
				color: #fff;
			}
		}
		.hostjane-nav__linkicon {
			margin-right: 8px;
			margin-left: 2px;
		}

		+ .hostjane-nav-dlink__children_popup {
			width: 464px;
			right: auto;
			left: 0;
			.hostjane-nav-dlink__children_container {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}

	.hostjane-desktop-nav__sellteach-body {
		color: #425466;
	}
	.hostjane-desktop-nav__sellteach-bodylayout {
		display: inline-grid;
		grid: auto/repeat(2, 1fr);
		gap: 20px 32px;
		width: 100%;
		padding: 20px 32px 20px;
	}
	.hostjane-desktop-nav__sellteach-bodyheader {
		grid-area: auto/span 2;
	}
	.hostjane-desktop-nav__sellteach-bodyheaderlink {
		display: inline-flex;
		color: #425466;
		font-size: 17px;
		outline: none;
		line-height: 1.428571429;
		text-decoration: none;
		.hostjane-desktop-nav__sellteach-bodyheaderlinkicon {
			color: #88add2;
			transition: color 0.15s;
		}
		&:hover {
			.hostjane-desktop-nav__sellteach-bodyheaderlinktexts {
				opacity: 0.6;
			}
			.hostjane-desktop-nav__sellteach-bodyheaderlinkicon {
				color: #425466;
			}
		}
	}
	.hostjane-desktop-nav__sellteach-bodyheaderlinkicon {
		height: 16px;
		width: 16px;
		margin: 3px 10px 0 0;
		flex: 0 0 auto;
	}
	.hostjane-desktop-nav__sellteach-bodyheaderlinktexts {
		white-space: nowrap;
		will-change: opacity;
		transition: opacity 0.15s;
	}
	.hostjane-desktop-nav__sellteach-bodyheaderlinklabel {
		font-weight: 600;
		display: block;
		color: #0a2540;
		font-size: 18px;
	}
	.hostjane-desktop-nav__sellteach-bodyheaderlinkbody {
		font-size: 17px;
	}
	.hostjane-desktop-nav__sellteach-listlist {
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
	}
	.hostjane-desktop-nav__sellteach-listitem {
		font-weight: 400;
		font-size: 17px;
		line-height: 1.428571429;
		list-style: none;
		margin: 0 0 0 20px;
		height: 24px;
		+ .hostjane-desktop-nav__sellteach-listitem {
			margin-top: 10px;
		}
	}
	.hostjane-desktop-nav__sellteach-listlink {
		text-decoration: none;
		display: inline-flex;
		color: #425466;
		outline: none;
		align-items: center;
		.hostjane-desktop-nav__sellteach-listlinkicon {
			color: #88add2;
			transition: color 0.15s;
		}
		.hostjane-desktop-nav__sellteach-listlinklabel {
			transition: opacity 0.15s;
			opacity: 1;
		}

		&.hostjane-main {
			.hostjane-desktop-nav__sellteach-listlinkicon,
			.hostjane-desktop-nav__sellteach-listlinkicon svg {
				height: 24px;
				width: 24px;
			}
			.hostjane-desktop-nav__sellteach-listlinkicon {
				margin-right: 5px;
				margin-top: -2px;
				margin-left: -4px;
			}
		}

		.HoverArrow {
			--arrowHoverTransition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
			--arrowHoverOffset: translateX(3px);
			position: relative;
			top: 1px;
			stroke-width: 2;
			fill: none;
			transition: opacity 0.15s;
			opacity: 1;

			.HoverArrow__linePath {
				opacity: 0;
				transition: opacity
					var(--hoverTransition, var(--arrowHoverTransition));
			}
			.HoverArrow__tipPath {
				transition: transform
					var(--hoverTransition, var(--arrowHoverTransition));
			}
		}

		&:hover {
			.HoverArrow,
			.hostjane-desktop-nav__sellteach-listlinklabel {
				opacity: 0.6;
			}
			.hostjane-desktop-nav__sellteach-listlinkicon {
				color: #425466;
			}
			.HoverArrow__linePath {
				opacity: 1;
			}
			.HoverArrow__tipPath {
				transform: var(--arrowHoverOffset);
			}
		}
	}
	.hostjane-desktop-nav__sellteach-listlinkicon,
	.hostjane-desktop-nav__sellteach-listlinkchevron {
		height: 16px;
		width: 16px;
		margin: 0 10px 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			height: 16px;
			width: 16px;
			fill: currentColor;
		}
	}
	.hostjane-desktop-nav__sellteach-listlinkchevron {
		margin: 1px 0 0 8px;
		height: 10px;
		width: 10px;
		svg {
			height: 10px;
			width: 10px;
		}
	}

	.hostjane-desktop-nav__sellteach-footer {
		margin: 4px;
		padding: 28px;
		border-radius: 4px;
		background-color: #f6f9fc;

		.hostjane-desktop-nav__sellteach-listitem {
			margin-left: 26px;
			&.hostjane-desktop-nav__sellteach-listitem--hasicon {
				font-weight: 600;
				margin-left: 0;
				font-size: 18px;

				.hostjane-desktop-nav__sellteach-listlink {
					color: #0a2540;
				}

				.hostjane-desktop-nav__sellteach-listlinkicon {
					margin-top: -2px;
				}

				+ .hostjane-desktop-nav__sellteach-listitem {
					margin-top: 18px;
				}
			}

			+ .hostjane-desktop-nav__sellteach-listitem {
				margin-top: 8px;
			}
		}
	}
`;

const LargeNavDropdownPopupContainer = styled.div`
	display: flex;
	position: absolute;
	top: 39px;
	z-index: 12;
	border: 1px solid ${props => props.theme.siteApp.desktopNavBorderColor};
	box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	z-index: 9999;
	flex-flow: row nowrap;
	justify-content: flex-start;

	left: ${props => props.left};
	width: ${props => props.width || 'auto'};
	right: ${props => props.right};
	border-radius: 0 0 3px 3px;
`;

const LargeNavDropdownPopupColumnWrap = styled.div`
	flex: 1 1 ${popupColumnWidth}px;
	width: ${popupColumnWidth}px;
	padding: 20px 0 28px;
	/** reduced padding for highlighted mega menu */
	&.hostjane-desktop-nav-dd-popup__column--highlighted {
		padding: 12px 0 18px;

		/** Make the first item of first column highlight */
		&:first-child {
			.hostjane-desktop-nav-dd-popup__itemslist {
				.hostjane-desktop-nav-dd-popup__item--highlighted:first-child {
					background-image: linear-gradient(
						45deg,
						#0b4182 1%,
						#1e88e5 64%,
						#40baf5 97%
					);
					color: #fff;
					.hostjane-desktop-nav-dd-popup__itemtitle {
						color: #fff;
					}
					.hostjane-desktop-nav-dd-popup__itemsubtitle {
						color: rgba(255, 255, 255, 0.9);
					}
				}
			}
		}
	}
	border-right: 1px solid
		${props => props.theme.siteApp.desktopNavBorderColor};
	&:last-of-type {
		border-right-color: transparent;
	}

	/** Column Title */
	.hostjane-desktop-nav-dd-popup__column-title {
		margin: 0;
		padding: 8px 32px;
		color: #767676;
		font-size: 18px;
		font-weight: 400;
		line-height: 1;
		font-style: italic;
	}

	/** Column Links */
	.hostjane-desktop-nav-dd-popup__item {
		display: block;
		padding: 7px ${props => props.theme.gutter.large}px;
		/** A bit different padding for big items */
		&.hostjane-desktop-nav-dd-popup__item--big {
			padding: 12px ${props => props.theme.gutter.large}px;
		}
		margin: 6px 0 7px;
		font-size: ${props => props.theme.siteApp.navFontSize}px;
		color: ${props => props.theme.siteApp.navAnchorColor};
		text-decoration: none;
		transition: background-color ${props => props.theme.siteApp.transition},
			color ${props => props.theme.siteApp.transition};
		will-change: background-color, color;

		&:hover {
			text-decoration: none;
			color: #2b9af3;
			background-color: #f4f4f4;
		}

		/** For regular */
		&.hostjane-desktop-nav-dd-popup__item--full {
			line-height: 1.4;
			transition: background-color 0.1s ease;
			padding: 8px 32px;
			font-size: 14px;
			margin: 0;

			.hostjane-desktop-nav-dd-popup__itemtitle {
				color: #111;
				font-size: 18px;
				font-weight: 800;
				padding: 0;
			}
			.hostjane-desktop-nav-dd-popup__itemsubtitle {
				color: #656565;
				font-size: 17px;
				font-weight: 400;
			}

			&:hover {
				background-color: #f5f5f5;
				.hostjane-desktop-nav-dd-popup__itemtitle {
					color: #0052cc;
				}
			}
		}

		/** For buttons */
		&.hostjane-desktop-nav-dd-popup__item--button {
			width: 200px;
			padding: 10px;
			margin: 0 0 12px ${props => props.theme.gutter.large}px;
			&:last-child {
				margin-bottom: 0;
			}
			background-color: ${props => props.theme.siteApp.accentColor};
			color: #fff;
			border-radius: 4px;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 43px;
			.hostjane-desktop-nav-dd-popup__itemtitle {
				margin: 0;
				text-align: center;
			}

			&:only-of-type {
				margin-top: 14px;
			}

			&:hover {
				background-color: ${props =>
					props.theme.siteApp.accentDarkerColor};
			}
			&:focus {
				box-shadow: 0 0 0 0.2rem
					${props => props.theme.siteApp.accentBoxShadowColor};
			}
		}
		&.hostjane-desktop-nav-dd-popup__item--full
			+ .hostjane-desktop-nav-dd-popup__item--button {
			margin-top: 8px;
			margin-right: 32px;
			margin-left: 32px;
			width: auto;
		}

		&.hostjane-desktop-nav-dd-popup__item--big
			+ .hostjane-desktop-nav-dd-popup__item--button {
			margin-top: 20px;
		}

		/** For image */
		&.hostjane-desktop-nav-dd-popup__item--image {
			padding: 12px 0 12px 21px;
			.hostjane-desktop-nav-dd-popup__itemimg {
				display: block;
				height: auto;
				border-radius: 4px;
				margin-top: 19px;
				width: 234px;
			}
		}

		/** For Big */
		&.hostjane-desktop-nav-dd-popup__item--big,
		&.hostjane-desktop-nav-dd-popup__item--image {
			position: relative;
			&:hover {
				color: #000;
			}
			.hostjane-desktop-nav-dd-popup__itemtitle {
				font-size: 18px;
				font-weight: 800;
				margin-bottom: 6px;
			}
			.hostjane-desktop-nav-dd-popup__itemsubtitle {
				font-size: 18px;
				font-weight: 400;
			}

			.hostjane-desktop-nav-dd-popup__itemchevron {
				position: absolute;
				right: 10px;
				top: 0;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				width: 20px;
			}
		}
	}

	.hostjane-desktop-nav-dd-popup__itemtitle {
		width: 100%;
		display: block;
		margin: 0 0 4px 0;
		font-weight: 800;
	}
	.hostjane-desktop-nav-dd-popup__itemsubtitle {
		width: 100%;
		display: block;
		color: #000000;
	}
`;

function PopupColumnLinkItem({ item, highlighted = false }) {
	const itemInner = (
		<>
			<span className="hostjane-desktop-nav-dd-popup__itemtitle">
				{item.title}
			</span>
			{item.subtitle ? (
				<span className="hostjane-desktop-nav-dd-popup__itemsubtitle">
					{item.subtitle}
				</span>
			) : null}
			{item.imageUrl && item.type === 'image' ? (
				<img
					className="hostjane-desktop-nav-dd-popup__itemimg"
					alt={item.title}
					src={item.imageUrl}
				/>
			) : null}
			{item.type === 'big' ? (
				<GenericIcon className="hostjane-desktop-nav-dd-popup__itemchevron">
					<ChevronRightIcon />
				</GenericIcon>
			) : null}
		</>
	);

	const className = classNames(
		'hostjane-desktop-nav-dd-popup__item',
		`hostjane-desktop-nav-dd-popup__item--${item.type}`,
		{
			'hostjane-desktop-nav-dd-popup__item--regular': !highlighted,
			'hostjane-desktop-nav-dd-popup__item--highlighted': highlighted,
		}
	);

	return item.internal ? (
		<Link to={item.href} className={className} role="menuitem">
			{itemInner}
		</Link>
	) : (
		<a href={item.href} className={className} role="menuitem">
			{itemInner}
		</a>
	);
}

function LargeNavDropdownPopupColumn({ nav, highlighted = false }) {
	return (
		<LargeNavDropdownPopupColumnWrap
			className={classNames('hostjane-desktop-nav-dd-popup__column', {
				'hostjane-desktop-nav-dd-popup__column--highlighted': highlighted,
			})}
		>
			{nav.title ? (
				<h4 className="hostjane-desktop-nav-dd-popup__column-title">
					{nav.title}
				</h4>
			) : null}
			<div className="hostjane-desktop-nav-dd-popup__itemslist">
				{nav.items.map((item, i) => (
					<PopupColumnLinkItem
						// eslint-disable-next-line react/no-array-index-key
						key={`item-${i}`}
						item={item}
						highlighted={highlighted}
					/>
				))}
			</div>
		</LargeNavDropdownPopupColumnWrap>
	);
}

const LargeNavDropdownLi = styled.li`
	flex: 1 0 auto;
	border-top: 1px solid ${props => props.theme.siteApp.desktopNavBorderColor};
	border-bottom: 1px solid
		${props => props.theme.siteApp.desktopNavBorderColor};
	display: flex;
	justify-content: center;

	.hostjane-desktop-nav-dropdown__anchor {
		font-size: 16px;
		padding: 0 ${props => props.theme.gutter.small}px;
		color: #2c2c2c;
		position: relative;
		display: flex;
		align-items: center;
		height: 38px;
		font-weight: 600;
		text-decoration: none;
		transition: color ${props => props.theme.siteApp.transition};
		will-change: color;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			height: 4px;
			width: 100%;
			bottom: -1px;
			background-color: ${props => props.theme.siteApp.primaryColor};
			opacity: 0;
			transition: opacity ${props => props.theme.siteApp.transition};
			will-change: opacity;
		}

		&:hover {
			color: ${props => props.theme.siteApp.navAnchorHoverColor};
			text-decoration: none;
			.hostjane-desktop-nav-dropdown__chevron {
				transform: rotate(-135deg);
				border-color: #4b4b4b;
			}
		}
	}

	.hostjane-desktop-nav-dropdown__chevron {
		display: flex;
		width: 8px;
		height: 8px;
		border-top-width: 0;
		border-left-width: 0;
		border-bottom-width: 1px;
		border-right-width: 1px;
		border-style: solid;
		transform-origin: 75% 75%;
		transform: rotate(45deg);
		transition: transform 0.1s ease;
		content: '';
		margin: -2px 0 0 8px;
		border-color: #2c2c2c;
	}

	&.hostjane-desktop-nav-dropdown__list--highlighted {
		flex-grow: 0;

		.hostjane-desktop-nav-dropdown__anchor {
			background-color: #e41937;
			transition: background-color
				${props => props.theme.siteApp.transition};
			will-change: background-color;
			color: #fff;

			&::after {
				display: none;
			}

			&:hover {
				background-color: #98012e;
				color: #fff;
			}
		}

		.hostjane-desktop-nav-dropdown__chevron {
			display: none;
		}

		&:hover {
			.hostjane-desktop-nav-dropdown__anchor {
				background-color: #98012e;
				color: #fff;
			}
		}
	}

	.hostjane-desktop-nav-dropdown__anchorwrap {
		${LargeNavDropdownPopupContainer} {
			display: none;
		}
		&:hover {
			.hostjane-desktop-nav-dropdown__anchor {
				&::after {
					opacity: 1;
				}
			}
			${LargeNavDropdownPopupContainer} {
				display: flex;
			}
		}
	}
`;

const LargeNavDropdownUl = styled.ul`
	display: flex;
	height: 40px;
	justify-content: stretch;
	align-items: stretch;
	list-style: none;
	padding: 0;
	margin: 25px 0 0 0;
	position: relative;
	font-size: ${props => props.theme.siteApp.navFontSize}px;
`;

function LargeNavDropdownPopupNav({ bounds, navs, highlighted = false }) {
	const numberOfColumns = navs.length;
	const navBounds = useMainNavBounds();

	// assume width is 100% and left is that of the wrapper div
	let width = '100%';
	let calculatedWidth = null;
	const offsetLeft = bounds.left - navBounds.left;
	let left = `${offsetLeft}px`;
	let right = 'auto';

	// but if number of columns is less, then adjust the width of dropdown
	if (numberOfColumns < 4) {
		calculatedWidth = numberOfColumns * popupColumnWidth;
		width = `${calculatedWidth}px`;
	}

	// if calculatedWidth overflows with container width, then readjust
	if (calculatedWidth && calculatedWidth + offsetLeft > navBounds.width) {
		left = 'auto';
		right = 0;
	}

	return (
		<LargeNavDropdownPopupContainer
			left={left}
			right={right}
			width={width}
			className={classNames('hostjane-desktop-nav-dd-popup', {
				'hostjane-desktop-nav-dd-popup--highlighted': highlighted,
			})}
		>
			{navs.map((nav, index) => (
				<LargeNavDropdownPopupColumn
					// eslint-disable-next-line react/no-array-index-key
					key={`column-${index}`}
					nav={nav}
					highlighted={highlighted}
				/>
			))}
		</LargeNavDropdownPopupContainer>
	);
}

function LargeNavDropdownListItem({
	link,
	title,
	internal = false,
	navs,
	highlighted = false,
}) {
	const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });

	return (
		<LargeNavDropdownLi
			className={classNames('hostjane-desktop-nav-dropdown__list', {
				'hostjane-desktop-nav-dropdown__list--highlighted': highlighted,
			})}
		>
			<div
				className="hostjane-desktop-nav-dropdown__anchorwrap"
				ref={ref}
			>
				{internal ? (
					<Link
						className="hostjane-desktop-nav-dropdown__anchor"
						to={link}
					>
						{title}
						<span className="hostjane-desktop-nav-dropdown__chevron" />
					</Link>
				) : (
					<a
						className="hostjane-desktop-nav-dropdown__anchor"
						href={link}
					>
						{title}
						<span className="hostjane-desktop-nav-dropdown__chevron" />
					</a>
				)}
				<LargeNavDropdownPopupNav
					bounds={bounds}
					navs={navs}
					highlighted={highlighted}
				/>
			</div>
		</LargeNavDropdownLi>
	);
}

function LargeNavDropdowns() {
	const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
	return (
		<LargeNavDropdownUl className="hostjane-desktop-nav-dropdown" ref={ref}>
			<mainNavBoundsContext.Provider value={bounds}>
				{/** Highlighted */}
				<LargeNavDropdownListItem
					link={desktopNavData.highlighted.link}
					title={desktopNavData.highlighted.title}
					internal
					navs={desktopNavData.highlighted.navs}
					highlighted
				/>
				{/** Others */}
				{desktopNavData.others.map((onav, index) => (
					<LargeNavDropdownListItem
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						link={onav.link}
						title={onav.title}
						navs={onav.navs}
					/>
				))}
			</mainNavBoundsContext.Provider>
		</LargeNavDropdownUl>
	);
}

export default function LargeNavigation({ landingPage }) {
	return (
		<DesktopNavHeader landingPage={landingPage}>
			<NavContainer className="hostjane-desktop-nav">
				<Hamburger_Main />
				<HostJaneLogoLink
					className="hostjane-desktop-nav__logo"
					to="https://www.hostjane.com"
					width={225}
				/>
				<NavDirectLinkWithChildren
					highlighted
					icon={SellTeachIcon}
					title={desktopNavData.topNav.sellTeach.title}
					className="hostjane-desktop-nav__sellteach"
				>
					<div className="hostjane-desktop-nav__sellteach-body">
						<div className="hostjane-desktop-nav__sellteach-bodylayout">
							<header className="hostjane-desktop-nav__sellteach-bodyheader">
								<a
									href={desktopNavData.topNav.sellTeach.link}
									className="hostjane-desktop-nav__sellteach-bodyheaderlink"
								>
									<span className="hostjane-desktop-nav__sellteach-bodyheaderlinkicon">
										<SellTeachIcon />
									</span>
									<span className="hostjane-desktop-nav__sellteach-bodyheaderlinktexts">
										<span className="hostjane-desktop-nav__sellteach-bodyheaderlinklabel">
											{
												desktopNavData.topNav.sellTeach
													.heading
											}
										</span>
										<span className="hostjane-desktop-nav__sellteach-bodyheaderlinkbody">
											{
												desktopNavData.topNav.sellTeach
													.subheading
											}
										</span>
									</span>
								</a>
							</header>
							{desktopNavData.topNav.sellTeach.links.map(
								(stLinks, index) => (
									<div
										className="hostjane-desktop-nav__sellteach-list"
										key={index}
									>
										<ul className="hostjane-desktop-nav__sellteach-listlist">
											{stLinks.map((stLink, lIndex) => (
												<li
													className="hostjane-desktop-nav__sellteach-listitem"
													key={lIndex}
												>
													<a
														href={stLink.link}
														className={classNames(
															'hostjane-desktop-nav__sellteach-listlink',
															stLink.className
														)}
													>
														<span className="hostjane-desktop-nav__sellteach-listlinkicon">
															<stLink.icon />
														</span>
														<span className="hostjane-desktop-nav__sellteach-listlinklabel">
															{stLink.title}
														</span>
													</a>
												</li>
											))}
										</ul>
									</div>
								)
							)}
						</div>
						<footer className="hostjane-desktop-nav__sellteach-footer">
							<div className="hostjane-desktop-nav__sellteach-list">
								<ul className="hostjane-desktop-nav__sellteach-listlist">
									<li
										className="hostjane-desktop-nav__sellteach-listitem hostjane-desktop-nav__sellteach-listitem--hasicon"
										key="builtin"
									>
										<a
											href={
												desktopNavData.topNav.sellTeach
													.popularAnswers.link
											}
											className={classNames(
												'hostjane-desktop-nav__sellteach-listlink'
											)}
										>
											<span className="hostjane-desktop-nav__sellteach-listlinkicon">
												<desktopNavData.topNav.sellTeach.popularAnswers.icon />
											</span>
											<span className="hostjane-desktop-nav__sellteach-listlinklabel">
												{
													desktopNavData.topNav
														.sellTeach
														.popularAnswers.title
												}
											</span>
										</a>
									</li>
									{desktopNavData.topNav.sellTeach.popularAnswers.links.map(
										(stLink, lIndex) => (
											<li
												className="hostjane-desktop-nav__sellteach-listitem"
												key={lIndex}
											>
												<a
													href={stLink.link}
													className={classNames(
														'hostjane-desktop-nav__sellteach-listlink',
														stLink.className
													)}
												>
													<span className="hostjane-desktop-nav__sellteach-listlinklabel">
														{stLink.title}
													</span>
													<span className="hostjane-desktop-nav__sellteach-listlinkchevron">
														<HoverArrowIcon />
													</span>
												</a>
											</li>
										)
									)}
								</ul>
							</div>
						</footer>
					</div>
				</NavDirectLinkWithChildren>
				<NavSearch showCategory cats={desktopNavData.searchCats} />
				<NavDirectLinkGroup className="hostjane-desktop-nav__links">
					{desktopNavData.topNav.navs.map((link, index) =>
						link.children && link.children.length ? (
							<NavDirectLinkWithChildren
								highlighted={link.highlighted}
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								icon={link.icon}
								title={link.title}
								items={link.children}
								popupTitle={link.popupTitle}
								noDecoration={link.noDecoration}
							/>
						) : (
							<NavDirectLink
								href={link.link}
								highlighted={link.highlighted}
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								dropOnSmallScreens={link.dropOnSmallScreens}
								addHoverBackground={link.addHoverBackground}
							>
								{link.title}
							</NavDirectLink>
						)
					)}
					<NavDivider height="40px" />
					<a
						href={desktopNavData.topNav.skills.link}
						className="hostjane-desktop-nav__skills"
					>
						<GenericIcon
							className="hostjane-desktop-nav__skillsicon"
							title={desktopNavData.topNav.skills.title}
						>
							<SkillsIcon />
						</GenericIcon>
						<span className="hostjane-desktop-nav__skillslabel">
							{desktopNavData.topNav.skills.title}
						</span>
					</a>
				</NavDirectLinkGroup>
			</NavContainer>
			<LargeNavDropdowns />
		</DesktopNavHeader>
	);
}
